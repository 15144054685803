import CheckboxSelectAll from '@stimulus-components/checkbox-select-all'

export default class extends CheckboxSelectAll {
  static targets = ["checkbox", "checkboxAll", "buttonAddSelected", "selectPage"]

  connect() {
    super.connect()
    this.updateSelectIds = this.updateSelectIds.bind(this)
    this.toggleAddButton = this.toggleAddButton.bind(this)
    this.updateTotalSelected = this.updateTotalSelected.bind(this)
    this.changePage = this.changePage.bind(this)
    this.toggleAddButton()
  }

  toggleAddButton() {
    this.updateSelectIds()
    this.updateTotalSelected()
  }

  toggle(e) {
    super.toggle(e)
    this.updateSelectIds()
    this.updateTotalSelected()
  }

  updateSelectIds() {
    if (this.checked.length > 0) {
      this.buttonAddSelectedTarget.classList.remove("opacity-65", "cursor-not-allowed")
      this.buttonAddSelectedTarget.disabled = false
    } else {
      this.buttonAddSelectedTarget.classList.add("opacity-65", "cursor-not-allowed")
      this.buttonAddSelectedTarget.disabled = true
    }
  }

  updateTotalSelected() {
    const totalBadge = document.querySelector('.total-selected')
    if (totalBadge) {
      const value = this.checked.length
      totalBadge.innerHTML = value
      if (value > 0) {
        this.buttonAddSelectedTarget.closest('form').classList.add('is-selected')
      } else {
        this.buttonAddSelectedTarget.closest('form').classList.remove('is-selected')
      }
    }
  }

  changePage(e) {
    const triggerButton = document.getElementById('searchAction');

    if (triggerButton) {
      document.getElementById('page').value = e.currentTarget.value;
      triggerButton.click();
    }
  }

  submitEnd() {
    this.checkboxAllTarget.checked = false
  }
}
