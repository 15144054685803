import { Controller } from "@hotwired/stimulus"

const tips = [
  "Nurture leads on social media.",
  "Everyone tells you something for a reason, Every word a prospect uses is trying to tell you something.",
  "The master has failed more times than the beginner has even tried.",
  "Get your No's Faster. Don’t drag it out. They keep you from focusing on the legit deals.",
  "Let the customer do most of the talking.",
  "Make the customer fall in love with your product or service.",
  "People don’t care how much you know until they know how much you care.",
  "Don’t overshare yourself out of a deal.",
  "Less is more.",
  "Problems equal opportunities.",
  "Sales is communication, communication is a transfer of energy! So keep motivated and enthusiastic, the prospect will definitely feel that energy too.",
  "Sometimes it's better to actively listen to the customer rather than pitch them features.",
  "Always be closing.",
  "In every deal be kind, reasonable, and honest. It won't win you 100% of deals, but it will turn all of your wins into continued business with the client.",
  "Know your customer’s business better than they do. Then you can provide real value.",
  "A successful salesperson does what unsuccessful salespeople won’t do.",
  "Track your sales metrics.",
  "People may forget what you say, but they’ll never forget how you made them feel.",
  "The product is almost always the least important thing in the closing deal.",
  "Start with specific niche markets.",
  "Use lead scoring to prioritize your prospects.",
  "Connect with the decision-makers.",
  "Make Exciting your sales pitch.",
  "Listen to your prospects.",
  "Emphasize the risks and opportunities.",
  "Develop the right mindset.",
  "Go beyond business.",
  "Reach out to SQL immediately.",
  "Follow the PAS framework.",
  "Target your Existing customers.",
  "Make the prospect realize that it is a final offer.",
  "Employ email automation.",
  "Keep track of customers’ records.",
  "Organize Healthy competition within the sales team.",
  "Strengthen your Buyer’s persona.",
  "Have a clear understanding of your clients.",
  "Bring the USP to your customers with a free trial.",
  "Align your sales and marketing team.",
  "Run sales offer unexpectedly."
];

export default class extends Controller {
  connect() {
    const that = this;
    this.toltalFilterCount = this.element.querySelectorAll('[data-controller="search--choices"], [data-controller="search--checkboxes"], [data-controller="search--size-range"]').length
    this.initCount = 0
    this.resultTarget = document.getElementById('records');
    this.searchAction = this.searchAction.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.clearSearchElement = this.clearSearchElement.bind(this);
    this.clearSearchAll = this.clearSearchAll.bind(this);
    this.gotoPage = this.gotoPage.bind(this);
    this.triggerSearch = this.triggerSearch.bind(this);
    this.toggleLoadingSearch = this.toggleLoadingSearch.bind(this)
    this.checkFilterInit = this.checkFilterInit.bind(this)
    this.updateActiveSearch = this.updateActiveSearch.bind(this);

    document.addEventListener("search--pagination:change", this.gotoPage);
    document.addEventListener("search--choices:change", this.triggerSearch);
    // document.addEventListener("search--checkboxes:change", this.triggerSearch);
    // document.addEventListener("search--size-range:change", this.triggerSearch)

    document.addEventListener("search--choices:init", this.checkFilterInit);
    document.addEventListener("search--checkboxes:init", this.checkFilterInit);
    document.addEventListener("search--size-range:init", this.checkFilterInit);

    this.element.addEventListener("submit", this.submitSearch.bind(this));
  }

  disconnect() {
    document.removeEventListener("search--pagination:change", this.gotoPage)
    document.removeEventListener("search--choices:change", this.triggerSearch)
    // document.removeEventListener("search--checkboxes:change", this.triggerSearch)
    // document.removeEventListener("search--size-range:change", this.triggerSearch)

    document.removeEventListener("search--choices:init", this.checkFilterInit);
    document.removeEventListener("search--checkboxes:init", this.checkFilterInit);
    document.removeEventListener("search--size-range:init", this.checkFilterInit);

    this.element.removeEventListener("submit", this.submitSearch.bind(this));
  }

  checkFilterInit(event) {
    this.initCount += 1;
    if (this.initCount >= this.toltalFilterCount) {
      setTimeout(this.searchAction(), 1000)
    }
  }

  submitSearch(event) {
    event.preventDefault();
    this.searchAction()
  }

  triggerSearch(event) {
    document.getElementById('page').value = 1;
    this.updateActiveSearch()
    this.searchAction()
  }

  gotoPage(event) {
    const that = this;
    const page = event.detail.page;
    document.getElementById('page').value = page;
    that.searchAction({ ss: true })
  }

  updateActiveSearch() {
    const badge = document.querySelector(`.badge-total`)
    const mobileBadge = document.querySelector('.badge-mobile-total')
    const values = document.getElementsByClassName('group/accordion filled-search')

    if (badge) {
      if (values.length > 0) {
        this.element.classList.add('is-search')
        badge.innerHTML = values.length
      } else {
        this.element.classList.remove('is-search')
        badge.innerHTML = values.length
      }
    }

    if (mobileBadge) {
      if (values.length > 0) {
        mobileBadge.innerHTML = values.length
        mobileBadge.classList.remove('hidden')
      } else {
        mobileBadge.innerHTML = values.length
        mobileBadge.classList.add('hidden')
      }
    }
  }

  toggleLoadingSearch() {
    this.resultTarget = document.getElementById('records');
    const tip = tips[Math.floor(Math.random()*tips.length)];
    // Convert the array to a JSON string and safely encode it for the attribute
    const tipsString = JSON.stringify(tips);
    // Add the string to the HTML element
    this.resultTarget.innerHTML = `<div id="slogan-container" data-controller="anime--slogan" class="grow flex flex-col items-center justify-center">
                                    <div role="status">
                                      <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                    <div id="slogan" data-anime--slogan-target="text" class="text-xl text-center font-light text-gray-600 dark:text-gray-400 mt-4 opacity-0 transition duration-500 position-relative anime--slogan">${tip}</div>
                                  </div>`;
    const sloganContainer = document.getElementById('slogan-container');
    if (sloganContainer) {
      sloganContainer.setAttribute('data-content', tipsString);
    }
  }

  searchAction(options = {}) {
    const button = options['reset'] ? this.element.querySelector('#resetSearch') : this.element.querySelector('#searchAction');
    this.toggleLoadingSearch();
    this.toggleDisableSearchTerms(true);
    this.toggleSubmitButton(button);
    // this.toggleOneClickButton()
    const that = this;
    const action = this.element.getAttribute('action');
    const method = this.element.getAttribute('method') || 'post';
    let params = new FormData(this.element);
    let ignoreParams = ['search_terms'];

    if (!options['ss']) {
      ignoreParams += ['ss[total_count]', 'ss[total_added]', 'ss[total_pages]']
    }

    // Create a new FormData object
    let filteredParams = new FormData();
    // Iterate over the existing FormData object
    for (let [key, value] of params.entries()) {
      if (!ignoreParams.includes(key)) {
        let element = document.querySelector(`[name="${key}"]`)
        if (element.closest('.filled-search') || element.closest('.default-search')) {
          filteredParams.append(key, value);
        }
      }
    }
    filteredParams.append('submit', `search`);
    const url = `${action}?${new URLSearchParams(filteredParams).toString()}`;

    fetch(url, {
      method: method,
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      }
    }).then(r => r.text()).then(html => {
      Turbo.renderStreamMessage(html)
      history.pushState({}, null, url);
      that.toggleDisableSearchTerms(false);
      that.updateActiveSearch();
      that.toggleSubmitButton(button);
      // that.toggleOneClickButton()
      return true
    }).then(data => tryCatch(() => that.toggleDisableSearchTerms(false) ));
  }

  clearSearch(event) {
    this.clearSearchElement(event.currentTarget.dataset.key, true)
  }

  clearSearchAll(event) {
    const that = this;
    const removeSearchTargets = document.getElementsByClassName('remove-search');
    // Convert NodeList to an Array and iterate
    Array.from(removeSearchTargets).forEach(function(element) {
      that.clearSearchElement(element.dataset.key, false)
    });
    that.searchAction({ reset: true });
  }

  clearSearchElement(key, reset = false) {
    const container = document.getElementById(`accordion-flush-body-${key}`);
    const accordion = document.getElementById(`accordion-flush-${key}`);
    const button = document.getElementById(`reset-${key}`);
    if (container) {
      accordion.classList.remove('filled-search');
      // Combine select and input selectors
      const elements = container.querySelectorAll('select, input');

      // Disable all found elements
      elements.forEach(function(element) {
        if (element.type == 'checkbox' || element.type == 'radio') {
          element.checked = false; // Uncheck checkboxes and radio buttons
        } else {
          element.value = ''; // Clear the value for other input types and select elements

          if (button) {
            button.click();
          }
        }
      });
      // Call the search action
      // if (reset) {
      //   this.searchAction();
      // }
    }
  }

  toggleAccordion(event) {
    const checkbox = event.currentTarget.querySelector(`#${event.currentTarget.getAttribute('for')}`);
    if (checkbox) {
      checkbox.checked = true;
    }
  }

  toggleDisableSearchTerms(status) {
    const searchTermsTargets = document.getElementsByName('search_terms');

    // Convert NodeList to an Array and iterate
    Array.from(searchTermsTargets).forEach(function(element) {
      element.disabled = status;
    });
  }

  toggleSubmitButton(button) {
    button ||= this.element.querySelector('#searchAction')
    const originalContent = button.innerHTML;
    const loadingContent = button.getAttribute('data-turbo-submits-with');
    button.setAttribute('data-turbo-submits-with', originalContent);

    // Set the button content to the loading state
    button.innerHTML = loadingContent;
    button.disabled = !button.disabled; // Optionally disable the button
  }

  toggleOneClickButton() {
    const button = document.getElementById('addOneClick')
    if (button) {
      const counter = document.getElementById('counter')
      if (counter) {
        button.classList.remove("opacity-65", "cursor-not-allowed")
        button.disabled = false
      } else {
        button.classList.add("opacity-65", "cursor-not-allowed")
        button.disabled = true
      }
    }
  }
}
